


















import { Component, Prop, Vue } from "vue-property-decorator";
import CapStoreApi, { StoreProduct } from "@/api/cap-store.api";
import SalesSnapCard from "./SalesSnapCard.vue";
import { List } from "vant";
import { getNow } from "@/utils/common.util";
import InteractionLib from "@/utils/interaction.lib";
import MemberApi from "@/api/member.api";

@Component({
  components: {
    List,
    SalesSnapCard,
  },
})
export default class SalesSnapCardList extends Vue {
  specialSale: StoreProduct[] = [];
  loading = false;
  finished = false;
  refreshing = false;
  page = 0;
  page_size = 10;
  isVipMember = false
  @Prop() noMore:any
  async created(){
    const { status } = await MemberApi.checkMemberIs()
    if(status == 1){
      this.isVipMember = true
    }
  }
  handleOpenURL(id: number): void {
    if (InteractionLib.isApp()) {
      return InteractionLib.openWebView(
        `${location.origin}/sp-store/${id}?time=${String(getNow())}`
      );
    }
    this.$router.push({
      name: "StoreDetail",
      params: { id: `${id}` },
      query: { time: String(getNow()) },
    });
  }
  get noMoreWord(){
    if(this.noMore){
      return ''
    }
    return '没有更多了'
  }
  async loadData(): Promise<void> {
    this.page++;
    const { page, page_size, specialSale } = this;
    const rows = await CapStoreApi.getSpecialSale(page, page_size).catch(
      () => []
    );
    this.loading = false;
   
    if (!rows.length || rows.length < this.page_size) this.finished = true;
    this.specialSale = page > 1 ? [...specialSale, ...rows] : rows;
  }
}
