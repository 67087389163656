








































































import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import SalesDisplayBtn from "./SalesDisplayBtn.vue";
import { countdown, momentFormatNot, observer } from "@/utils/common.util";
import type { StoreProduct } from "@/api/cap-store.api";

@Component({
  components: {
    SalesDisplayBtn,
  },
})
export default class SalesSnapCard extends Vue {
  @Ref("image_ref") private readonly imgRefs!: HTMLImageElement;
  @Ref("sales_medium") private readonly salesMedium!: HTMLDivElement;
  @Ref("sales_medium_other") private readonly salesMediumOther!: HTMLDivElement;
  @Ref("drawing") private readonly drawing!: HTMLDivElement;
  @Prop() isVipMember!:boolean;
  @Prop() private readonly item!: StoreProduct;
  @Prop() private readonly customStyle!: string | any;
  countdown_text = "";
  is_start = true;
  start_text = "";
  timer: any;
  flag: any = true;
  countdown: any;

  @Emit()
  handleClick(): void {
    return;
  }
  get price() {
    if(this.isVipMember){
        return this.item.gold_price || this.item.price
    }
    return this.item.gold_price&&this.item.gold_unique?this.item.gold_price:this.item.price;
  }
  get is_finish(): boolean {
    const { finish_time } = this.item;
    return finish_time <= Math.ceil(Date.now() / 1000);
  }

  get snapUp(): boolean {
    const { repertory } = this.item;
    return Boolean(repertory);
  }

  get is_gold(): boolean {
    if(this.isVipMember){
      return Boolean(this.item.gold_price);
    }
    return Boolean(this.item.gold_price&&this.item.gold_unique);
  }

  get deduction_money(): number {
    const { bazaar_price, price, gold_price } = this.item;
    return Math.ceil(
      (Math.ceil(bazaar_price * 100) -
        Math.ceil((this.is_gold ? gold_price : price) * 100)) /
        100
    );
  }

  get desc_price(): string {
    const { bazaar_price, bazaar_text } = this.item;
    return `${bazaar_text}¥${bazaar_price}`;
  }

  mounted(): void {
    observer("countdownNotice", () => {
      const time = this.item.finish_time || 0;
      const { day, hour, minute, second } = countdown(time);
      this.countdown_text =
        Number(day) > 7
          ? ""
          : `<div class="__sales_countdown">距结束还有 ${day}天</div><span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>`;
      if (this.item.start_time > Math.ceil(Date.now() / 1000)) {
        this.is_start = true;
      } else if (this.is_start) {
        this.is_start = false;
      }
      const start = countdown(this.item.start_time || 0);

      if (
        Number(start.day) > 0 ||
        Number(start.hour) > 0 ||
        Number(start.minute) > 0 ||
        Number(start.second) > 0
      ) {
        this.timer = setInterval(() => {
          const { day, hour, minute, second } = countdown(this.item.start_time);
          if (
            Number(day) == 0 &&
            Number(hour) == 0 &&
            Number(minute) == 0 &&
            Number(second) == 0
          ) {
            this.flag = false;
          }
          if (this.flag) {
            this.start_text = `
        <div class="felx-row-salessnap"><div class="countdown-tip">距开售还有${day}天</div>
        <div class="countdown-text-salessnape">
          <span>${hour}</span>:<span>${minute}</span>:<span>${second}</span>
        </div></div>
      `;
          }
        }, 1000);
      }
    });
    const { clientWidth, offsetWidth } = this.imgRefs;
    this.imgRefs.style.height = (clientWidth || offsetWidth) + "px";
    const { clientHeight: s2_ch, offsetHeight: s2_oh } = this.salesMediumOther;
    const height = (clientWidth || offsetWidth) - (s2_ch || s2_oh) - 10;
    this.drawing.style.height = height + "px";
  }
}
