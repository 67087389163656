



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import { Coupon } from "@/api/mine.api";

@Component({})
export default class SalesDisplayBtn extends Vue {
  @Prop({ default: false }) round!: boolean | string;
  @Prop({ default: false }) snapUp!: boolean | string;
  @Prop({ default: false }) progress!: boolean | string;
  @Prop({ default: false }) isshow!: boolean | string;
  @Prop({ default: 0 }) private readonly price!: number;
  @Prop({ default: "" }) private readonly descPrice!: string;
  @Prop({ default: "" }) private readonly discount!: string;
  @Prop({ default: 0 }) private readonly inventory!: number;
  @Prop({ default: 0 }) private readonly repertory!: number;
  @Prop({ default: "" }) private readonly countdown!: string;
  @Prop({ default: null }) private readonly coupon!: Coupon | null;

  @Prop({ default: true }) private readonly showLightning!: boolean;
  @Prop({ default: false }) private readonly gold!: boolean;
  @Prop({ default: false }) private readonly is_start!: boolean;

  get progress_text() {
    if (this.repertory === 0) return 0;
    return Math.floor(
      ((this.inventory - this.repertory) / this.inventory) * 100
    );
  }

  get is_round(): boolean {
    return this.round !== false;
  }

  get is_snap_up(): boolean {
    return this.snapUp !== false;
  }
}
